<template>
  <b-modal
    id="modalConfiguracoesEcac"
    ref="modalConfiguracoesEcac"
    size="xl"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    header-class="pb-0"
  >
    <template #modal-title>
      <h5>Configurações rotinas e-CAC por empresa - {{ value.razaoSocial }}</h5>
      <fieldset
        class="font-weight-bold text-muted small"
        style="margin-top: 10px; margin-block-end: 10px;"
      >
        Marque ou desmarque as rotinas que sua empresa tem junto ao e-CAC.
      </fieldset>
    </template>

    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <b-btn
          variant="none"
          size="sm"
          class="btn-outline-success btn-none"
          style="margin-left: 0.3rem;"
          @click="salvarConfiguracoes()"
        >
          Salvar
        </b-btn>
        <b-btn
          variant="none"
          size="sm"
          class="btn-outline-danger btn-none"
          style="margin-left: 0.3rem;"
          @click="close(true)"
        >
          Cancelar
        </b-btn>
      </div>
    </template>

    <div
      v-for="(grupo, $index) in grupos"
      :key="$index"
      class="mt-1 mb-3"
    >
      <h6
        style="margin-top: 0.15rem"
        class="text-uppercase"
      >
        {{ grupo.nome }}
      </h6>
      <b-row>
        <b-col
          v-for="configuracao in grupo.configuracoes"
          :key="configuracao.nome"
          cols="3"
        >
          <b-form-checkbox
            :checked="selecionado(configuracao.valor)"
            style="margin-top: 0.75rem"
            switch
            @change="selecionar(configuracao.valor)"
          >
            {{ configuracao.descricao }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </div>
    <h6
      style="margin-top: 0.15rem"
      class="text-uppercase"
    >
      CERTIFICADO DIGITAL
    </h6>
    <b-row>
      <b-col
        cols="3"
      >
        <b-form-checkbox
          v-model="config.responsavelLegal"
          style="margin-top: 0.75rem"
          switch
        >
          Responsável legal
        </b-form-checkbox>
      </b-col>
    </b-row>
  </b-modal>
</template>

<style lang="scss">

#modalConfiguracoesEcac {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: center !important;

  .modal-xl {
    display: flex;
    width: calc(60vw);
  }
}

</style>

<script>

import api from '@/app/empresas/shared/services/api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ModalConfiguracoesEcac',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      configInIn: {
        responsavelLegal: false,
        processos: [],
      },
      config: {
        responsavelLegal: false,
        processos: [],
      },
      grupos: this.getPermissaoEcac(),
    }
  },
  watch: {
    value(val, oldVal) {
      if (val !== oldVal) {
        this.config = val.configuracao
      }
    },
  },
  mounted() {
    this.configIn = this.value.configuracao
    this.config = this.value.configuracao
    this.show()
  },
  methods: {
    show() {
      this.$refs.modalConfiguracoesEcac.show()
    },
    close(reset = false) {
      if (reset) {
        this.value.configuracao = this.configIn
      }
      this.$emit('hidden')
    },
    selecionar(configuracao) {
      const selecionado = this.config.processos
      const index = selecionado.indexOf(configuracao)
      if (index >= 0) {
        this.config.processos.splice(index, 1)
      } else {
        this.config.processos.push(configuracao)
      }
    },
    selecionado(configuracao) {
      return this.config.processos.includes(configuracao)
    },
    salvarConfiguracoes() {
      api.atualizarConfiguracoesEcac(this.value.id, this.config)
        .then(payload => {
          if (!payload.data) {
            throw new Error()
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível salvar as configurações das rotinas.',
              icon: 'fa-solid fa-circle-exclamation',
              variant: 'danger',
            },
          })
          throw err
        })
        .finally(() => {
          this.$refs.modalConfiguracoesEcac.hide()
          this.$emit('hidden')
        })
    },
    getPermissaoEcac() {
      const permissoes = this.getPermissaoLocal()
      const grupos = [{
        nome: 'Federal',
        configuracoes: [{ valor: 1, descricao: 'DCTFWeb' }],
      },
      {
        nome: 'Parcelamentos',
        configuracoes: [{ valor: 2, descricao: 'Simples Nacional' },
          { valor: 4, descricao: 'PERT SN' },
          { valor: 8, descricao: 'RELP SN' },
          { valor: 16, descricao: 'RFB' },
          { valor: 32, descricao: 'Não Previdenciário' },
          { valor: 512, descricao: 'Simplificado Previdenciário' },
          { valor: 64, descricao: 'PGFN' },
        ],
      },
      {
        nome: 'Simples Nacional',
        configuracoes: [{ valor: 128, descricao: 'DAS' },
          { valor: 256, descricao: 'Sublimite' },
        ],
      },
      ]

      return this.filtrarGruposPorPermissoes(grupos, permissoes)
    },
    filtrarGruposPorPermissoes(grupos, permissoesAjustadas) {
      return grupos.map(grupo => {
        const configuracoesPermitidas = grupo.configuracoes.filter(config => permissoesAjustadas.includes(config.descricao))
        if (configuracoesPermitidas.length > 0) {
          return { ...grupo, configuracoes: configuracoesPermitidas }
        }
        return null
      })
        .filter(grupo => grupo !== null)
    },
    getPermissaoLocal() {
      const permissoes = localStorage.getItem('permissoes').split(',')
      const array = []
      for (let i = 0; i < permissoes.length; i += 1) {
        if (permissoes[i] === 'declaracao_dctfweb') {
          array.push('DCTFWeb')
        }
        if (permissoes[i] === 'das_parcelamento') {
          array.push('Simples Nacional')
        }
        if (permissoes[i] === 'parcelamento_relpsn') {
          array.push('RELP SN')
        }
        if (permissoes[i] === 'parcelamento_pertsn') {
          array.push('PERT SN')
        }
        if (permissoes[i] === 'parcelamento_rfb') {
          array.push('RFB')
        }
        if (permissoes[i] === 'parcelamento_naoprevidenciario') {
          array.push('Não Previdenciário')
        }
        if (permissoes[i] === 'pgfn_parcelamento') {
          array.push('PGFN')
        }
        if (permissoes[i] === 'parcelamento_simp_previdenciario') {
          array.push('Simplificado Previdenciário')
        }
        if (permissoes[i] === 'sublimite') {
          array.push('Sublimite')
        }
        if (permissoes[i] === 'das') {
          array.push('DAS')
        }
      }
      return array
    },
  },
}
</script>
